<script>
import ViewPurchase from '@/pages/purchase/ViewPurchase';
import ViewSale from '@/pages/sale/ViewSale';
import ViewRemortgage from '@/pages/remortgage/ViewRemortgage';

export default {
  name: 'ViewEntity',
  components: { ViewPurchase, ViewSale, ViewRemortgage },
  props: ['entityType', 'entityId'],
};
</script>

<template>
  <div>
    <ViewSale v-if="entityType === 'sale'" :entityType="entityType" :entityId="entityId" />
    <ViewPurchase v-if="entityType === 'purchase'" :entityType="entityType" :entityId="entityId" />
    <ViewRemortgage v-if="entityType === 'remortgage'" :entityType="entityType" :entityId="entityId" />
  </div>
</template>
<style lang="scss" scoped></style>
